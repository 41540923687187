<template>
    <DataTable paginator :value="stateVendors.vendors" :rowsPerPageOptions="[5, 10, 20]" lazy
        :loading="stateVendors.loading.vendors" :currentPage="stateVendors.currentPage" :rows="stateVendors.perPage"
        ref="dt" dataKey="id" :totalRecords="stateVendors.totalRecords" :autoLayout="true" @page="onPage($event)">
        <Column field="id" header="ID">
            <template #body="slotProps">
                {{ slotProps.data.id }}
            </template>
        </Column>
        <Column field="name" header="Fornecedor">
            <template #body="slotProps">
                {{ slotProps.data.name }}
            </template>
        </Column>
        <Column field="type" header="Tipo">
            <template #body="slotProps">
                {{ slotProps.data.type }}
            </template>
        </Column>
        <Column field="type" header="Taxa de Depósito">
            <template #body="slotProps">
                {{ formatTransactionTaxValue(slotProps.data.deposit_tax_type, slotProps.data.deposit_tax_value) ?? '' }}
            </template>
        </Column>

        <Column field="type" header="Taxa de Saque">
            <template #body="slotProps">
                {{ formatTransactionTaxValue(slotProps.data.withdraw_tax_type, slotProps.data.withdraw_tax_value) ?? '' }}
            </template>
        </Column>
        <Column field="active" header="Ativo">
            <template #body="slotProps">
                {{ changeStatusBoleanToText(slotProps.data.active) }}
            </template>
        </Column>
        <Column field="username" header="Usuário">
            <template #body="slotProps">
                {{ slotProps.data.username }}
            </template>
        </Column>
        <Column field="merchant_id" header="Merchant ID">
            <template #body="slotProps">
                {{ slotProps.data.merchant_id }}
            </template>
        </Column>
        <Column field="name" header="Token">
            <template #body="slotProps">
                {{ slotProps.data.token }}
            </template>
        </Column>
        <Column>
            <template #header>
                Ações
            </template>
            <template #body="slotProps">
                <div class="container-button">
                    <Button type="button" icon="pi pi-pencil" @click="openDetailsModal(slotProps.data)"
                        v-tooltip.bottom="{ value: 'Editar', showDelay: 1000, hideDelay: 300 }" />
                </div>
            </template>
        </Column>
    </DataTable>

    <Dialog v-model:visible="state.detailsModal" modal :header="state.vendor.name" :style="{ maxWidth: '400px' }">
        <div class="grid grid-nogutter">
            <div class="col-12 mb-2">
                <div class="surface-ground w-full p-2 px-3 border-round">
                    <i style="width:18px" class="pi pi-tag"></i>
                    <span class="text-md uppercase font-semibold text-600"> ID: {{ state.vendor.id }}</span>
                </div>
            </div>
        </div>
        <div class="col-12 mb-2 text-center">
            <div class="ml-1 my-3">
                <div class="text-500">Nome:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedVendor.name || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedVendor.name" autofocus />
                    </template>
                </Inplace>
            </div>
            <div class="ml-1 my-3">
                <div class="text-500">Status:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ changeStatusBoleanToText(state.modifiedVendor.active) || 'Vazio' }}
                    </template>
                    <template #content>
                        <Dropdown class="mt-1" v-model="state.modifiedVendor.active" :options="['Ativado', 'Desativado']" />
                    </template>
                </Inplace>
            </div>
            <div class="ml-1 my-3">
                <div class="text-500 ">Taxa de Depósito:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ formatTransactionTaxValue(state.modifiedVendor.deposit_tax_type,
                            state.modifiedVendor.deposit_tax_value) || 'Vazio' }}
                    </template>
                    <template #content>
                        <div class="flex flex-column">
                            <Dropdown class="" v-model="state.depositTax" optionLabel="value"
                                :options="stateVendors.taxTypes" />
                            <InputNumber class="" v-model="state.modifiedVendor.deposit_tax_value"
                                :prefix="state.depositPrefixSymbol" :maxFractionDigits="2" />
                        </div>
                    </template>
                </Inplace>
            </div>

            <div class="ml-1 my-3">
                <div class="text-500 ">Taxa de Saque:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ formatTransactionTaxValue(state.modifiedVendor.withdraw_tax_type,
                            state.modifiedVendor.withdraw_tax_value) || 'Vazio' }}
                    </template>
                    <template #content>
                        <div class="flex flex-column">
                            <Dropdown class="" v-model="state.withdrawTax" optionLabel="value"
                                :options="stateVendors.taxTypes" />
                            <InputNumber class="" v-model="state.modifiedVendor.withdraw_tax_value"
                                :prefix="state.withdrawPrefixSymbol" :maxFractionDigits="2" />
                        </div>
                    </template>
                </Inplace>
            </div>


            <div class="ml-1 my-3">
                <div class="text-500">Usuário:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedVendor.username || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedVendor.username" autofocus />
                    </template>
                </Inplace>
            </div>
            <div class="ml-1 my-3">
                <div class="text-500">Senha:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        <i class="pi pi-eye-slash"></i>
                    </template>
                    <template #content>
                        <Password v-model="state.modifiedVendor.password" :feedback="false" />
                    </template>
                </Inplace>
            </div>
            <div class="ml-1 my-3">
                <div class="text-500">Merchant ID:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedVendor.merchant_id || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedVendor.merchant_id" autofocus />
                    </template>
                </Inplace>
            </div>
            <div class="ml-1 my-3">
                <div class="text-500">Token:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedVendor.token || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedVendor.token" autofocus />
                    </template>
                </Inplace>
            </div>
            <div class="ml-1 my-3">
                <div class="text-500">Chave:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        <i class="pi pi-eye-slash"></i>
                    </template>
                    <template #content>
                        <Password v-model="state.modifiedVendor.key" :feedback="false" />
                    </template>
                </Inplace>
            </div>
        </div>
        <br>

        <div><button id="save-vendor" class="p-button" @click="$refs.checkChanges.toggle">Conferir Alterações</button></div>
        <OverlayPanel showCloseIcon ref="checkChanges">
            <div v-for="(item, index) in changedItems()" :key="index">
                <div>{{ state.vendorLabels[item.field] || item.field }}</div>
                <div v-if="item.field === 'active'" class="text-center">{{ changeStatusBoleanToText(item.original) }}</div>
                <div v-else-if="item.field === 'deposit_tax_type' || item.field === 'withdraw_tax_type'"
                    class="text-center">
                    {{ getTrasactionTypeLabel(item.original) }}
                </div>
                <div v-else class="text-center">
                    <i v-if="item.field === 'key' || item.field === 'password'" class="pi pi-eye-slash" />
                    <span v-else>{{ item.original }}</span>
                </div>
                <div class="text-center"><i class="pi pi-arrow-circle-down text-green-400"></i></div>
                <div class="text-center">
                    <i v-if="item.field === 'key' || item.field === 'password'" class="pi pi-key" />
                    <div v-else-if="item.field === 'deposit_tax_type' || item.field === 'withdraw_tax_type'"
                        class="text-center">
                        {{ getTrasactionTypeLabel(item.modified) }}
                    </div>
                    <span v-else> {{ item.modified }}</span>
                </div>
                <hr style="border-color: #fff3">
            </div>
            <div v-if="changedItems().length == 0">
                Nenhuma Alteração
            </div>
            <div v-else>
                <button :disabled="state.loading.save" class="p-button bg-green-400 hover:bg-green-600"
                    @click="saveChanges">Salvar Mudanças</button>
            </div>
        </OverlayPanel>
    </Dialog>
</template>

<script>
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import Inplace from 'primevue/inplace'
import Dropdown from 'primevue/dropdown'
import Password from 'primevue/password'
import OverlayPanel from 'primevue/overlaypanel'
import InputNumber from 'primevue/inputnumber'
import { reactive, watch } from 'vue'

import services from '../../services'
import useNotificationToast from '../../composables/useNotificationToast'
import useVendors from '../../composables/useVendors'
import { useStore } from 'vuex'

export default {
    components: { Button, DataTable, Column, Dialog, Inplace, Dropdown, Password, OverlayPanel, InputNumber },

    setup() {
        const state = reactive({

            vendor: {},
            modifiedVendor: {},
            depositTax: null,
            depositPrefixSymbol: null,

            withdrawTax: null,
            withdrawPrefixSymbol: null,
            loading: {
                save: false,
            },

            currentPage: 1,
            perPage: 5,
            totalRecords: 0,

            detailsModal: false,
            checkChangesModal: false,

            vendorLabels: {

                name: 'Nome',

                active: 'Status',

                deposit_tax_type: 'Tipo de Taxa de Depósito',

                deposit_tax_value: 'Valor de Taxa de Depósito',

                withdraw_tax_type: 'Tipo de Taxa de Saque',

                withdraw_tax_value: 'Valor de Taxa de Saque',

                usename: 'Usuário',

                password: 'Senha',

                merchant_id: 'Merchant ID',

                token: 'Token',

                key: 'Chave',

            }
        })

        watch(() => state.depositTax, () => {
            state.depositPrefixSymbol = getInputNumberPrefixSymbol(state.depositTax)
            state.modifiedVendor.deposit_tax_type = state.depositTax.type
        })

        watch(() => state.withdrawTax, () => {
            state.withdrawPrefixSymbol = getInputNumberPrefixSymbol(state.withdrawTax)
            state.modifiedVendor.withdraw_tax_type = state.withdrawTax.type
        })

        const store = useStore()
        const { success, error } = useNotificationToast()

        const { stateVendors, loadVendors, onPage, getInputNumberPrefixSymbol } = useVendors()
        loadVendors()

        function openDetailsModal(vendor) {
            state.vendor = vendor
            state.modifiedVendor = { ...vendor }
            state.detailsModal = true
        }

        function changedItems() {
            const changed = []

            for (const key in state.vendor) {
                const original = state.vendor[key]
                const modified = state.modifiedVendor[key]
                if (original != modified) {
                    changed.push({
                        original,
                        modified,
                        field: key
                    })
                }
            }
            return changed
        }

        function changeStatusBoleanToText(active) {
            return active ? 'Ativado' : 'Desativado'
        }

        function formatTransactionTaxValue(transactionTaxType, transactionTaxValue) {

            if (transactionTaxType == null || transactionTaxValue == null) {
                return ''
            } else {
                return transactionTaxType === 'REAL' ? `R$ ${transactionTaxValue}` : `${transactionTaxValue} %`
            }

        }

        function saveChanges() {
            state.loading.save = true


            const modifiedVendor = state.modifiedVendor
            if (modifiedVendor.active === 'Ativado' || modifiedVendor.active === 'Desativado') {
                modifiedVendor.active = (modifiedVendor.active === 'Ativado' || modifiedVendor.active === true) ? true : false
            }
            const token = store.getters.getToken

            services.vendors.update({ modifiedVendor, token }).then(() => {
                success('Fornecedor atualizado com sucesso')
                state.detailsModal = false
                loadVendors()
            }).catch(() => {
                error("Não foi possível atualizar o fornecedor.")
            }).finally(() => {
                state.loading.save = false
            })

        }

        function getTrasactionTypeLabel(item) {
            return item === 'REAL' ? 'Real R$' : 'Porcentagem %'
        }


        return {
            state,
            stateVendors,
            onPage,
            openDetailsModal,
            changedItems,
            changeStatusBoleanToText,
            formatTransactionTaxValue,
            getInputNumberPrefixSymbol,
            saveChanges,
            getTrasactionTypeLabel
        }

    }
}
</script>